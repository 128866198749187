<template>
    <v-container fluid style="padding:0px;" :class="displaySizeClass">
        <v-row no-gutters class="text-center py-4 colorWhite poppinsLightFont" justify="center" style="background-color:#055c67;">
            <v-col cols="12">
                <h1 class="lusciousLifestylesScriptFont mb-8" style="font-size:90px;">{{titleLabel.split("|")[languageId]}}</h1>
            </v-col>
            <v-col cols="12" class="mb-4" v-if="isSmallScreen != 'Mobile'">
                <div class="mx-8 px-8 py-2" style="text-align:justify; border: 1px solid #D79A2B;">
                    <h2 class="px-2 text-yellow poppinsSemiboldFont" style="margin-top:-20px; background-color:#055c67; width:max-content; text-transform: uppercase; font-size:16px;">{{stepsLabel.split("|")[languageId]}}</h2>
                    <div class="px-4 my-2" v-if="languageId == 0">
                        <p>1. Consigue una <span class="text-yellow poppinsSemiboldFont">hoja tamaño carta</span>. No importa si es blanca, hoja de revista o cualquier otra. Lo importante es que sea tamaño carta (21,6x27,9cm)</p>
                        <p>2. <span class="text-yellow poppinsSemiboldFont">Pégala en el espacio</span> donde te gustaría tener una de mis fotos.</p>
                        <p>3. Presiona el boton <span class="text-yellow poppinsSemiboldFont">"Probar"</span> para los siguientes pasos.</p>
                    </div>
                    <div class="px-4 my-2" v-if="languageId == 1">
                        <p>1. Find a <span class="text-yellow poppinsSemiboldFont">letter-size sheet of paper</span>. It doesn't matter if it's white, a magazine sheet or any other. The important thing is that it is letter size (8.5 x 11 in)
                        </p>
                        <p>2. <span class="text-yellow poppinsSemiboldFont">Put it in the space</span> where you would like to have one of my photos.
                        </p>
                        <p>3. Click the <span class="text-yellow poppinsSemiboldFont">"Try"</span> button to continue whith the next steps.
                        </p>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" class="mb-4" v-if="isSmallScreen == 'Mobile'">
                <div class="mx-4 px-4 py-2" style="text-align:justify; border: 1px solid #D79A2B;">
                    <h2 class="px-2 text-yellow poppinsSemiboldFont" style="margin-top:-20px; background-color:#055c67; width:max-content; text-transform: uppercase; font-size:16px;">{{stepsLabel.split("|")[languageId]}}</h2>
                    <div class="px-2 my-2" v-if="languageId == 0">
                        <span>1. </span>
                        <p>Consigue una <span class="text-yellow poppinsSemiboldFont">hoja tamaño carta</span>. No importa si es blanca, hoja de revista o cualquier otra. Lo importante es que sea tamaño carta (21,6x27,9cm)</p>
                        <span>2. </span>
                        <p><span class="text-yellow poppinsSemiboldFont">Pégala en el espacio</span> donde te gustaría tener una de mis fotos.</p>
                        <span>3. </span>
                        <p>Presiona el boton <span class="text-yellow poppinsSemiboldFont">"Probar"</span> para los siguientes pasos.</p>
                    </div>
                    <div class="px-2 my-2" v-if="languageId == 1">
                        <span>1. </span>
                        <p>Find a <span class="text-yellow poppinsSemiboldFont">letter-size sheet of paper</span>. It doesn't matter if it's white, a magazine sheet or any other. The important thing is that it is letter size (8.5 x 11 in)
                        </p>
                        <span>2. </span>
                        <p><span class="text-yellow poppinsSemiboldFont">Put it in the space</span> where you would like to have one of my photos.
                        </p>
                        <span>3. </span>
                        <p>Click the <span class="text-yellow poppinsSemiboldFont">"Try"</span> button to continue whith the next steps.
                        </p>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="6" lg="6" xl="6" class="px-6">
                <div class="px-6" style="display:flex;flex-direction:row;">
                    <div style="width:max-content;">
                        <h3 class="text-yellow poppinsSemiboldFont" style="text-align:left;width:max-content;text-transform: uppercase; ">{{exampleLable.split("|")[languageId]}}</h3>
                    </div>
                    <div class="px-4" style="width:100%">
                        <hr style="margin-top:14px;color: #D79A2B;background-color: #D79A2B;border-color: #D79A2B;"/>
                    </div>
                </div>
                <div class="px-6 py-2">
                    <div style="max-height:500px;">
                        <v-img src="../../assets/Simulator/ejemplo_simulador.png" :alt="languageId==0?'Imagen guia para ubicar la hoja carta y utilizar el simulador de Karlus Morales':'Guide image to locate the letter sheet and use Karlus Morales simulator'" style="border-radius:50px;" contain max-height="500px"></v-img>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="6" lg="6" xl="6" class="px-6 py-2">
                <div class="px-6" style="display:flex;flex-direction:row;">
                    <div style="width:max-content;">
                        <h3 class="text-yellow poppinsSemiboldFont" style="text-align:left;width:max-content;text-transform: uppercase; ">{{tutorialLabel.split("|")[languageId]}}</h3>
                    </div>
                    <div class="px-4" style="width:100%">
                        <hr style="margin-top:14px;color: #D79A2B;background-color: #D79A2B;border-color: #D79A2B;"/>
                    </div>
                </div>
                <div class="px-6 videoWrapper" style="height:95%;">
                    <div style="max-height:500px; height:100%; width:100%" v-if="isSmallScreen != 'Mobile'">
                        <iframe src="https://www.youtube.com/embed/0KopNvouXsw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style="width=560px; height=349px;"></iframe>
                    </div>
                    <div style="max-height:500px; height:100%; width:100%" v-if="isSmallScreen == 'Mobile'">
                        <iframe src="https://www.youtube.com/embed/ETXjzOxAWH0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style="width=560px; height=349px;"></iframe>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" class="my-8">
                <v-btn color="secondary" @click="NextStep(1)">{{tryLabel.split('|')[languageId]}}</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name:"Step0",
    props: ['languageId'],
    data:()=>({
        titleLabel:"Previsualizar|Preview",
        stepsLabel:"Paso a paso|Step by step",
        exampleLable:"Ejemplo|Example",
        tryLabel:"Probar|Try",
        tutorialLabel:"Video tutorial|Video tutorial",
    }),
    computed:{
        isSmallScreen(){
            var screenType = "Desktop";
            if(this.displaySizeClass == 'mobile-size')
                screenType = "Mobile";
            if(this.displaySizeClass == 'tablet-size')
                screenType = "Tablet";
            return screenType
        },
        displaySizeClass(){
            var displayClass = "desktop-size";
            var windowWidth = this.widthSize;
            if(windowWidth <= 640)
                displayClass = "mobile-size";
            if(windowWidth >= 1024 && windowWidth <= 1440)
                displayClass = "tablet-size";
            return displayClass
        },
        widthSize(){
            var windowWidth = window.innerWidth;
            if(windowWidth < 320)
                windowWidth = 320
            if(windowWidth > 640 && windowWidth < 1024)
                windowWidth = 640
            if(windowWidth > 1440 && windowWidth < 1920)
                windowWidth = 1440
            if(windowWidth > 1920)
                windowWidth = 1920
            return windowWidth
        },
    },
    methods:{
        NextStep(step){
            this.$emit('NextStep', step)
        },
    }
}
</script>

<style>
    .videoWrapper {
        position: relative;
        padding-bottom: calc(56.25% * 0.93); /* 16:9 */
        width: 93%;
        padding-top: 25px;
        height: 0;
    }
    .videoWrapper iframe {
        position: absolute;
        top: 0;
        left: 24px;
        width: 100%;
        height: 100%;
    }
</style>