<template>
<div>
	<v-container fluid style="padding:0px; margin-bottom: -128px;" :class="displaySizeClass" v-if="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'">
		<v-row no-gutters class="text-center" style="margin-top:-128px; height:128px;">
			<v-col cols="12" style="background-color:#303030;">
			</v-col>
        </v-row>
        <v-row no-gutters class="text-center" justify="center">
            <div class="colorYellow poppinsRegularFont" style="height:48px; display:flex; flex-direction: row; justify-content: space-evenly; align-items:center;">
                <span class="cursorPointer uppercase mr-2" @click="$router.push('/')">
                    {{homeButtonLabel[languageId]}}
                </span>
                <span class="ml-2 mr-2" style="cursor: context-menu">/</span>
                <span class="cursorPointer uppercase ml-2 mr-2" @click="$router.push(`/${baseArtPiecesUrl[languageId]}`)">
                    {{artPiecesButtonLabel[languageId]}}
                </span>
                <span class="ml-2 mr-2" style="cursor: context-menu">/</span>
                <span class="uppercase ml-2 poppinsSemiboldFont">
                    {{currentPage.split("|")[languageId]}}
                </span>
            </div>
        </v-row>
        <!-- <v-row no-gutters class="text-center" justify="center">
            <v-col cols="12">
                <h2 v-if="isSmallScreen=='Tablet'">{{["En construcción","Under construction"][languageId]}}</h2>
            </v-col>
        </v-row> -->
        <step0 v-if="currentStep==0" @NextStep="(step) => NextStep(step)" :languageId="languageId"></step0>
        <step1 v-if="currentStep==1" @NextStep="(step) => NextStep(step)" :languageId="languageId"></step1>

		<footerComponent :backgroundImage="footerImage"></footerComponent>
    </v-container>
    <v-container fluid style="padding:0px; margin-bottom: -128px;" :class="displaySizeClass" v-if="isSmallScreen=='Mobile'">
        <v-row no-gutters class="text-center" justify="center">
            <div class="colorYellow poppinsRegularFont" style="height:48px; display:flex; flex-direction: row; justify-content: space-evenly; align-items:center;">
                <span class="cursorPointer uppercase mr-2" @click="$router.push('/')">
                    {{homeButtonLabel[languageId]}}
                </span>
                <span class="ml-2 mr-2" style="cursor: context-menu">/</span>
                <span class="cursorPointer uppercase ml-2 mr-2" @click="$router.push(`/${baseArtPiecesUrl[languageId]}`)">
                    {{artPiecesButtonLabel[languageId]}}
                </span>
                <span class="ml-2 mr-2" style="cursor: context-menu">/</span>
                <span class="uppercase ml-2 poppinsSemiboldFont">
                    {{currentPage.split("|")[languageId]}}
                </span>
            </div>
        </v-row>
        <v-row no-gutters class="text-center" justify="center">
            <v-col cols="12">
                <h2 v-if="isSmallScreen=='Tablet'">{{["En construcción","Under construction"][languageId]}}</h2>
            </v-col>
        </v-row>
        <step0 v-if="currentStep==0" @NextStep="(step) => NextStep(step)" :languageId="languageId"></step0>
        <step1 v-if="currentStep==1" @NextStep="(step) => NextStep(step)" :languageId="languageId"></step1>

		<footerComponent :backgroundImage="footerImage"></footerComponent>
    </v-container>
</div>
</template>

<script>
import footerComponent from './components/Footer'
import { mapState, /*mapActions*/ } from "vuex";
import Step0 from './Simulator/Step0'
import Step1 from './Simulator/Step1'
export default {
    name:"Simulator",
    components: { footerComponent, Step0, Step1 },
    data:()=>({
        pageTitle:['Simulador Karlus Morales','Art Pieces Preview Karlus Morales'],
        pageMetaTitle:['Simulador Karlus Morales','Art Pieces Preview Karlus Morales'],
        pageDescription:['Simulador de espacios de Karlus Morales','Karlus Morales Art Piece preview'],
        pageKeywords:['Simulador','Art Pieces Preview'],
        metaImage:'',
        metaImageID:73,
        requiresReload:false,
        currentPage:"Previsualizar|Preview",
        takeFotoLabel:"Tomar foto|Take foto",
        useFotoLabel:"Usar otra foto|Use another photo",
        showFotoLabel:"Mostrar foto|Show photo",
        currentStep:0,
        footerImage:require('../assets/About/About_Desktop_06.jpg'),
    }),
    computed:{
        ...mapState("shoppingCart", 
            [
                "collectionNames", "priceRange", "artPieces", "collectionFilter", "priceFilter", "sortByList", "languageId", "ShowingPages",
                "lastVisited", "filterLabel", "sortBy", "sizeFilter", "homeButtonLabel", "artPiecesButtonLabel",
                "baseArtPiecesUrl","images","artPiecesOk",
            ]),
        isSmallScreen(){
            var screenType = "Desktop";
            if(this.displaySizeClass == 'mobile-size')
                screenType = "Mobile";
            if(this.displaySizeClass == 'tablet-size')
                screenType = "Tablet";
            return screenType
        },
        displaySizeClass(){
            var displayClass = "desktop-size";
            var windowWidth = this.widthSize;
            if(windowWidth <= 640)
                displayClass = "mobile-size";
            if(windowWidth >= 1024 && windowWidth <= 1440)
                displayClass = "tablet-size";
            return displayClass
        },
        widthSize(){
            var windowWidth = window.innerWidth;
            if(windowWidth < 320)
                windowWidth = 320
            if(windowWidth > 640 && windowWidth < 1024)
                windowWidth = 640
            if(windowWidth > 1440 && windowWidth < 1920)
                windowWidth = 1440
            if(windowWidth > 1920)
                windowWidth = 1920
            return windowWidth
        },
    },
    metaInfo(){
        return{
            title: this.pageTitle[this.languageId],
            meta: [
                {
                vmid: 'robots',
                name: 'robots',
                content: 'index, follow',
                },
                {
                vmid: 'title',
                name: 'title',
                content: this.pageMetaTitle[this.languageId],
                },
                {
                vmid: 'description',
                name: 'description',
                content: this.pageDescription[this.languageId],
                },
                {
                vmid: 'keywords',
                name: 'keywords',
                content: this.pageKeywords[this.languageId],
                },
                {
                vmid: 'og:title',
                property: 'og:title',
                content: this.pageMetaTitle[this.languageId],
                },
                {
                vmid: 'og:description',
                property: 'og:description',
                content: this.pageDescription[this.languageId],
                },
                {
                vmid: 'og:image',
                property: 'og:image',
                content: this.metaImage,
                },
                {
                vmid: 'og:url',
                property: 'og:url',
                content: `${window.location.href}`,
                },
                {
                vmid: 'twitter:title',
                name: 'twitter:title',
                content: this.pageMetaTitle[this.languageId],
                },
                {
                vmid: 'twitter:description',
                name: 'twitter:description',
                content: this.pageDescription[this.languageId],
                },
                {
                vmid: 'twitter:image',
                name: 'twitter:image',
                content: this.metaImage,
                },
                {
                vmid: 'twitter:url',
                name: 'twitter:url',
                content: `${window.location.href}`,
                },
                {
                vmid: 'twitter:card',
                name: 'twitter:card',
                content: 'summary',
                },
            ]
        }
    },
    methods:{
		getImageById(idImage){
			if(this.images != null){
				let image = this.images.find(x=>x.id == idImage)
				return image.base64
			}
		},
		selectMetaImage(idImage){
			idImage = idImage!=null?idImage:this.metaImageID;
			let mImage = this.getImageById(idImage)
			if(mImage != null){
				return window.location.origin + require(`../assets/${mImage}`)
			}
        },
        NextStep(step){
            this.currentStep = step;
        },
    },
	mounted(){
		if(this.artPiecesOk){
			this.metaImage = this.selectMetaImage(this.metaImageID);
		} else {
			this.requiresReload = true;
		}
	},
	updated(){
		if(this.requiresReload && this.artPiecesOk){
			this.metaImage = this.selectMetaImage(this.metaImageID);
			this.requiresReload = false;
		}
	}
}
</script>
