<template>
    <div class="img-magnifier-container" ondragstart="return false;" id="picdiv_2" style="margin:auto; background-color:transparent; background-size:contain; " :style="`width:${imageWidth}px;height:${imageHeight}px;background-image:url(${imageSRC});${allowDrawing?'touch-action:none;':''}`"
        @mousedown="startPosition"
        @touchstart="touchStart($event)"
        @mouseup="endPosition"
        @touchend="touchEnd($event)"
        @touchmove="touchMove($event)"
        @mousemove="mouseMove($event)"
        @mouseenter="mouseEnter($event)"
        @mouseleave="mouseLeave($event)"
        @touchenter="touchEnter"
        @touchleave="touchLeave"
    >
        <svg v-if="!selectedImage" xmlns="http://www.w3.org/2000/svg" version="1.1" :width="imageWidth" :height="imageHeight" id="piccanvas_1" >
            <rect :x="positionX" :y="positionY" :width="sizeX" :height="sizeY" style="fill:rgb(215,154,43,0.6);" id="drawrec_1" />
            <circle v-if="showResize==true" ondragstart="return false;" class="resizeRB" id="resizeRB" fill="blue" :cx="positionX+sizeX" :cy="positionY+sizeY" r="10px" style="cursor:se-resize;"
                @mousedown="RightBottom($event)"
                @touchstart="RightBottom($event)"
            />
            <circle v-if="showResize==true" ondragstart="return false;" class="resizeLT" id="resizeLT" fill="blue" :cx="positionX" :cy="positionY" r="10px" style="cursor:se-resize;"
                @mousedown="LeftTop($event)"
                @touchstart="LeftTop($event)"
            />
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'RectangleDrawing',
        props:[
            'imageWidth',
            'imageHeight',
            'imageSRC',
            'allowDrawing',
            'selectedImage'
        ],
        data: () => ({
            inicioX:0,
            inicioY:0,
            finalX:0,
            finalY:0,
            sizeX:1,
            sizeY:1,
            startPos:[],
            endPos:[],
            mdrag:false,
            tdrag:false,
            picframe:null,
            bclient:null,
            iconUrl: require('../../assets/Simulator/crosshair.png'),
            showResize:false,
            mousedown_pointsLT:{x:null,y:null},
            mousedown_pointsRB:{x:null,y:null},
        }),
        watch:{
            allowDrawing(newValue){
                if(newValue){
                    this.resetRectangle();
                    this.showResize=false
                }
            }
        },
        computed:{
            positionX(){
                if(this.inicioX>this.finalX) return this.finalX
                else return this.inicioX
            },
            positionY(){
                if(this.inicioY>this.finalY) return this.finalY
                else return this.inicioY
            },
            appBar(){
                return 50;
            },
        },
        methods:{
            LeftTop(e){
                var target = e.target;
                // if (e.touches) { e = e.touches[0]; }
                // if (e.touches) { this.magnify("picdiv_2", 3, e); e = e.touches[0]; } else { this.magnify("picdiv_2", 3, e); }  //mover esta parte a mousemove pero no se como llamar una funcion afuera
                if (target.id === 'resizeLT') {
                    if (e.touches) {
                        this.mousedown_pointsLT = {
                            x: e.touches[0].clientX,
                            y: e.touches[0].clientY
                        }
                    } else {
                        this.mousedown_pointsLT = {
                            x: e.clientX,
                            y: e.clientY
                        }
                    }
                    window.addEventListener('mouseup', this.mouseupLT);
                    window.addEventListener('mousemove', this.mousemoveLT);
                    // document.addEventListener('mousemove', this.magnify("picdiv_2", 3, e), false);
                    window.addEventListener('touchend', this.mouseupLT);
                    window.addEventListener('touchmove', this.mousemoveLT);
                    // document.addEventListener('touchmove', this.magnify("picdiv_2", 3, e), false);
                    this.magnify("picdiv_2", 3, e)
                }
            },
                mousemoveLT(e) {
                    if (e.touches) { e = e.touches[0]; }
                    var current_points = {
                        x: e.clientX,
                        y: e.clientY
                    }

                    var rect= document.getElementById('drawrec_1');
                    var w=parseFloat(rect.getAttribute('width'));
                    var h=parseFloat(rect.getAttribute('height'));

                    var dx=current_points.x-this.mousedown_pointsLT.x;
                    var dy=current_points.y-this.mousedown_pointsLT.y;

                    w-=dx;
                    h-=dy;

                    rect.setAttribute('width',w);
                    rect.setAttribute('height',h);
                    rect.setAttribute('x',parseFloat(rect.getAttribute('x'))+dx);
                    rect.setAttribute('y',parseFloat(rect.getAttribute('y'))+dy);

                    this.mousedown_pointsLT=current_points;

                    this.updateResizeIconLT(dx,dy);
                },
                updateResizeIconLT(dx,dy){
                    var resize= document.getElementById('resizeLT');
                    var x=parseFloat(resize.getAttribute('cx'));
                    var y=parseFloat(resize.getAttribute('cy'));

                    x+=dx;
                    y+=dy;

                    resize.setAttribute('cx',x);
                    resize.setAttribute('cy',y);

                    this.changeInitialPosition(x,y);
                    this.calcular();
                    const picframe = document.getElementById('picdiv_2');
                    this.$emit('position',[picframe.offsetTop+y,picframe.offsetLeft+x]);
                },
                mouseupLT() {
                    window.removeEventListener('mouseup', this.mouseupLT);
                    window.removeEventListener('mousemove', this.mousemoveLT);
                    window.removeEventListener('touchend', this.mouseupLT);
                    window.removeEventListener('touchmove', this.mousemoveLT);
                    const element = document.getElementById('mag_glass');
                    try {
                        element.remove();
                    } catch {
                        null;
                    }
                    this.calcular()
                },
            RightBottom(e){
                var target = e.target;
                // if (e.touches) { e = e.touches[0]; }
                // if (e.touches) { this.magnify("picdiv_2", 3, e); e = e.touches[0]; } else { this.magnify("picdiv_2", 3, e); }  //mover esta parte a mousemove pero no se como llamar una funcion afuera
                if (target.id === 'resizeRB') {
                    if (e.touches) {
                        this.mousedown_pointsRB = {
                            x: e.touches[0].clientX,
                            y: e.touches[0].clientY
                        }
                    } else {
                        this.mousedown_pointsRB = {
                            x: e.clientX,
                            y: e.clientY
                        }
                    }
                    window.addEventListener('mouseup', this.mouseupRB);
                    window.addEventListener('mousemove', this.mousemoveRB);
                    // document.addEventListener('mousemove', this.magnify("picdiv_2", 3, e), false);
                    window.addEventListener('touchend', this.mouseupRB);
                    window.addEventListener('touchmove', this.mousemoveRB);
                    // document.addEventListener('touchmove', this.magnify("picdiv_2", 3, e), false);
                    this.magnify("picdiv_2", 3, e)
                }
            },
                mousemoveRB(e) {
                    if (e.touches) { e = e.touches[0]; }
                    var current_points = {
                        x: e.clientX,
                        y: e.clientY
                    }

                    var rect= document.getElementById('drawrec_1');
                    var w=parseFloat(rect.getAttribute('width'));
                    var h=parseFloat(rect.getAttribute('height'));

                    var dx=current_points.x-this.mousedown_pointsRB.x;
                    var dy=current_points.y-this.mousedown_pointsRB.y;

                    w+=dx;
                    h+=dy;

                    rect.setAttribute('width',w);
                    rect.setAttribute('height',h);

                    this.mousedown_pointsRB=current_points;

                    this.updateResizeIconRB(dx,dy);
                    // this.magnify("picdiv_2", 3, e)
                },
                 updateResizeIconRB(dx,dy){
                    var resize= document.getElementById('resizeRB');
                    var x=parseFloat(resize.getAttribute('cx'));
                    var y=parseFloat(resize.getAttribute('cy'));

                    x+=dx;
                    y+=dy;

                    resize.setAttribute('cx',x);
                    resize.setAttribute('cy',y);

                    this.changeFinalPosition(x,y);
                },
                mouseupRB() {
                    window.removeEventListener('mouseup', this.mouseupRB);
                    window.removeEventListener('mousemove', this.mousemoveRB);
                    window.removeEventListener('touchend', this.mouseupRB);
                    window.removeEventListener('touchmove', this.mousemoveRB);
                    const element = document.getElementById('mag_glass');
                    try {
                        element.remove();
                    } catch {
                        null;
                    }
                    this.calcular()
                },
            resetRectangle(){
                this.sizeX = 1;
                this.sizeY = 1;
            },
            touchStart(event){
                if(/*this.inicioX != 0 && this.inicioY != 0*/!this.allowDrawing) return;
                // this.showResize=true;
                let touches = event.touches;
                if(!this.allowDrawing) return null;
                if(touches.length != 1) return null;
                let touch = touches[0];
                this.picframe = document.querySelector('#piccanvas_1');
                this.bclient = this.picframe.getBoundingClientRect();
                const x = touch.clientX - this.bclient.x;
                const y = Number(touch.clientY - Math.ceil(this.bclient.y));
                this.changeStartPosition(x,y);
                this.startPos = [Number(y + touch.target.parentElement.offsetTop), x];
                this.isDrawing(true);
                this.tdrag = true;
                this.touchEnter(event);
            },
            touchEnd(event){
                if(this.tdrag) this.showResize=true;
                this.tdrag = false;
                this.isDrawing(false);
                this.touchLeave(event);
                if(/*this.finalX != 0 && this.finalY != 0*/!this.allowDrawing) return;
                let touches = event.changedTouches;
                if(!this.allowDrawing) return null;
                if(touches.length != 1) return null;
                let touch = touches[0];
                this.picframe = document.querySelector('#piccanvas_1');
                this.bclient = this.picframe.getBoundingClientRect();
                const x = touch.clientX - this.bclient.x;
                const y = Number(touch.clientY - Math.ceil(this.bclient.y));
                this.changeEndPosition(x,y);
                this.endPos = [Number(y + touch.target.parentElement.offsetTop), x];
                this.positionCoordinates();
            },
            touchMove(event){
                if (event.type == "touchmove") {
                    if (this.tdrag) {
                        this.isDrawing(true);
                        this.touchEnter(event);
                        let touches = event.changedTouches;
                        let touch = touches[0];
                        this.picframe = document.querySelector('#piccanvas_1');
                        this.bclient = this.picframe.getBoundingClientRect();
                        const x = touch.clientX - this.bclient.x;
                        const y = Number(touch.clientY - Math.ceil(this.bclient.y));
                        this.changeEndPosition(x,y);
                        this.endPos = [Number(y + touch.target.parentElement.offsetTop), x];
                        this.positionCoordinates();
                    }
                }
            },
            mouseMove(event){
                if (event.type == "mousemove") {
                    if (this.mdrag) {
                    this.isDrawing(true);
                    const x = event.offsetX;
                    const y= event.offsetY;
                    this.changeEndPosition(x,y);
                    this.endPos = [event.layerY, event.layerX]
                    this.positionCoordinates();
                    }
                }
            },
            startPosition(event){
                if(/*this.inicioX != 0 && this.inicioY != 0*/!this.allowDrawing) return;
                // this.showResize=true
                const x = event.offsetX;
                const y= event.offsetY;
                this.changeStartPosition(x,y);
                this.startPos = [event.layerY, event.layerX]
                this.isDrawing(true);
                this.mdrag = true;
            },
            endPosition(event){                
                if(this.mdrag) this.showResize=true;
                this.mdrag = false;
                this.isDrawing(false);
                this.mouseLeave(event);
                if(/*this.finalX != 0 && this.finalY != 0*/!this.allowDrawing) return;
                const x = event.offsetX;
                const y= event.offsetY;
                this.changeEndPosition(x,y);
                this.endPos = [event.layerY, event.layerX]
                this.positionCoordinates();
            },
            mouseEnter(event){
                if(!this.allowDrawing) return null;
                this.magnify("picdiv_2", 3, event);
            },
            mouseLeave(event){
                if(event.toElement?.id != "picdiv_2" && event.toElement?.id != "mag_glass" && event.toElement?.id != "resizeRB" && event.toElement?.id != "resizeTL"){
                    const element = document.getElementById('mag_glass');
                    try {
                        element.remove();
                    } catch {
                        null;
                    }
                }
            },
            touchEnter(event){
                if(!this.allowDrawing) return null;                
                // event = event || 0;
                this.magnify("picdiv_2", 3, event);
            },
            touchLeave(){
                // event = event || 0;
                const element = document.getElementById('mag_glass');
                try {
                    element.remove();
                } catch {
                    null;
                }
            },            
            changeInitialPosition(x,y){
                this.inicioX = x;
                this.inicioY = y;
            },            
            changeFinalPosition(x,y){
                this.finalX = x;
                this.finalY = y;
            },
            changeStartPosition(x,y){
                this.changeInitialPosition(x,y);
                this.resetRectangle();
            },
            changeEndPosition(x,y){
                this.changeFinalPosition(x,y)
                this.calcular()
            },
            calcular(){
                let x = Math.abs(this.inicioX - this.finalX);
                let y = Math.abs(this.inicioY - this.finalY);
                this.sizeX = x;
                this.sizeY = y;
                this.$emit('changeSize',[x,y])
            },
            positionCoordinates(){
                let x = this.startPos[0]>this.endPos[0]?this.endPos[0]:this.startPos[0];
                let y = this.startPos[1]>this.endPos[1]?this.endPos[1]:this.startPos[1];
                this.$emit('position',[x,y])
            },
            isDrawing(value){
                this.$emit('recDrawing',value)
            },
            magnify(imgID, zoom, event) {
                var img, glass, w, h, bw;
                // event = event || 0;
                img = document.getElementById(imgID);

                /* Create magnifier glass: */
                try {
                    glass = document.getElementById("mag_glass");
                    if(glass == null){
                        glass = document.createElement("img");
                        glass.setAttribute("class", "img-magnifier-glass");
                        glass.setAttribute("id", "mag_glass");
                        glass.setAttribute("ondragstart", false);
                        glass.style.filter = "brightness(85%) contrast(150%)";
                        glass.style.visibility = "hidden";
                        // glass.style.display = "none";
                        /* Insert magnifier glass: & crosshair: */
                        img.parentElement.insertBefore(glass, img);
                        glass.src = this.iconUrl;
                    }
                } catch {
                    glass = document.createElement("img");
                    glass.setAttribute("class", "img-magnifier-glass");
                    glass.setAttribute("id", "mag_glass");
                    glass.setAttribute("ondragstart", false);
                    glass.style.filter = "brightness(85%) contrast(150%)";
                    glass.style.visibility = "hidden";
                    // glass.style.display = "none";
                    /* Insert magnifier glass: & crosshair: */
                    img.parentElement.insertBefore(glass, img);
                    glass.src = this.iconUrl;
                }


                /* Set background properties for the magnifier glass: */
                //   glass.img = "url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxbNmOg5uzwTJgWxhVsgQXD5P8NAqoH3DHYA&usqp=CAU)"
                glass.style.backgroundImage = "url('" + this.imageSRC + "')";
                //   glass.style.backgroundImage = "url('" + img.style.backgroundImage + "')"
                glass.style.backgroundRepeat = "no-repeat";
                glass.style.backgroundSize = (parseInt(img.style.width,10) * zoom) + "px " + (parseInt(img.style.height,10) * zoom) + "px";
                bw = 3;
                w = glass.offsetWidth / 2;
                h = glass.offsetHeight / 2;

                if (event == 0){
                    null;
                } else {
                    moveMagnifier(event);
                }

                /* Execute a function when someone moves the magnifier glass over the image: */
                // glass.addEventListener("mousemove", moveMagnifier);
                img.addEventListener("mousemove", moveMagnifier);
                img.addEventListener("mouseup", removeMagnifier);

                // /*and also for touch screens:*/
                // glass.addEventListener("touchmove", moveMagnifier);
                img.addEventListener("touchmove", moveMagnifier);
                img.addEventListener("touchend", removeMagnifier);

                function removeMagnifier(){
                    img.removeEventListener('mouseup', removeMagnifier);
                    img.removeEventListener('mousemove', moveMagnifier);
                    img.removeEventListener('touchend', removeMagnifier);
                    img.removeEventListener('touchmove', moveMagnifier);
                }
                
                function moveMagnifier(e) {
                    var pos, x, y;
                    /* Prevent any other actions that may occur when moving over the image */
                    try {
                        e.preventDefault();
                    } catch {
                        null;
                    }
                    /* Get the cursor's x and y positions: */
                    pos = getCursorPos(e);
                    x = pos.x;
                    y = pos.y;
                    /* Prevent the magnifier glass from being positioned outside the image: */
                    // if (x > parseInt(img.style.width,10) - (w / zoom) + 100) {x = parseInt(img.style.width,10) - (w / zoom) + 100;}
                    if (x > parseInt(img.style.width,10) - 15) {x = parseInt(img.style.width,10) - 15;}
                    // if (x < (w / zoom) + 100) {x = (w / zoom) + 100;}
                    if (x < 115) {x = 115;}
                    // if (y > parseInt(img.style.height,10) - (h / zoom) + 100) {y = parseInt(img.style.height,10) - (h / zoom) + 100;}
                    if (y > parseInt(img.style.height,10) - 15) {y = parseInt(img.style.height,10) - 15;}
                    // if (y < (h / zoom) + 100) {y = (h / zoom) + 100;}
                    if (y < 115) {y = 115;}
                    /* Set the position of the magnifier glass: */
                    const picframe = document.getElementById('picdiv_2');
                    const offTop = picframe.offsetTop;
                    const offLeft = picframe.offsetLeft;
                    glass.style.left = (x - w + offLeft - 65) + "px";
                    glass.style.top = (y - h + offTop - 65) + "px";
                    /* Display what the magnifier glass "sees": */
                    if(e){
                        if(e.srcElement?.id != "resizeRB" && e.srcElement?.id != "resizeLT"){
                            glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px";
                        } else {
                            if(e.type == "mousemove" || e.type == "mousedown" || e.type == "mouseup") {
                                const dragcirc = document.getElementById(e.srcElement.id);
                                glass.style.backgroundPosition = "-" + (((x - (e.offsetX-dragcirc.cx.animVal.value)) * zoom) - w + bw) + "px -" + (((y - (e.offsetY-dragcirc.cy.animVal.value)) * zoom) - h + bw) + "px";
                            } else if(e.type == "touchmove" || e.type == "touchstart" || e.type == "touchend") {
                                let touches = e.changedTouches;
                                let touch = touches[0];
                                let picframe2 = document.querySelector('#piccanvas_1');
                                let bclient2 = picframe2.getBoundingClientRect();
                                const xt = touch.clientX - bclient2.x;
                                const yt = Number(touch.clientY - Math.ceil(bclient2.y));
                                const dragcirc = document.getElementById(e.srcElement.id);
                                glass.style.backgroundPosition = "-" + (((x - (xt-dragcirc.cx.animVal.value)) * zoom) - w + bw) + "px -" + (((y - (yt-dragcirc.cy.animVal.value)) * zoom) - h + bw) + "px";
                            }
                        }
                        glass.style.visibility = "visible";
                        // glass.style.display = "inline-block";
                    }
                }

                function getCursorPos(e) {
                    var a, x = 0, y = 0;
                    e = e || window.event;
                    /* Get the x and y positions of the image: */
                    a = img.getBoundingClientRect();
                    /* Calculate the cursor's x and y coordinates, relative to the image: */
                    if (e.type == "mousemove" || e.type == "mousedown") {
                        x = e.pageX - a.left;
                        y = e.pageY - a.top;
                    } else if (e.type == "touchmove" || e.type == "touchstart") {
                        let touches = e.changedTouches;
                        let t = touches[0];
                        x = t.pageX - a.left;
                        y = t.pageY - a.top;
                    }
                    /* Consider any page scrolling: */
                    x = x - window.pageXOffset;
                    y = y - window.pageYOffset;
                    return {x : x, y : y};
                }
            },
        },
    }
</script>

<style>
    * {box-sizing: border-box;}

    /* .img-magnifier-container {
    position: relative;
    } */

    .img-magnifier-glass {
    position: absolute;
    border-radius: 50%;
    cursor: none;
    vertical-align:middle;
    user-select: none;
    mix-blend-mode: luminosity;
    /*Set the size of the magnifier glass:*/
    width: 100px;
    height: 100px;
    }
</style>