<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog"
            :width="this.$vuetify.breakpoint.name!='xs'?1500:310"
            @click:outside="close()"
        >

            <v-card>
                <v-card-title class="text-center colorYellow">
                    <div style="display:flex; flex-direction:row; width:100%; justify-content:center;">
                        <div style="width:40%;">
                            <hr class="mt-4" style="color:#D79A2B;"/>
                        </div>
                        <div>
                            <h5 class="poppinsRegularFont mx-6" style="text-transform:uppercase; font-size:22px;">Galería</h5>
                        </div>
                        <div style="width:40%;">
                            <hr class="mt-4" style="color:#D79A2B;"/>
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="error"
                            @click="close()"
                            icon
                        >
                            <v-icon >mdi-close</v-icon>
                        </v-btn>
                    </div>
                </v-card-title>

                <v-card-text>
                    
                    <div style="display:flex; justify-content:space-evenly; flex-flow:row wrap;" v-if="dataReady" class="mt-2 mb-6">
                        <div v-for="(artPiece,index) in availableArtPieces" :key="index" class="mx-2 my-2 rounded-lg" :style="selectedPhoto==artPiece.title?'border:3px solid #035b67;':''">
                            
                            <v-hover v-slot="{hover}">
                                <v-card
                                    tile
                                    width=310
                                    height=330
                                    min-width=220
                                    min-height=250
                                    :flat="!(hover || selectedPhoto==artPiece.title)"
                                    class="rounded-lg"
                                    @click="select(artPiece.title)"
                                > 
                                    <v-img
                                        class="rounded-lg"
                                        :src="require(`../../assets/${getImage(artPiece.title)}`)"
                                        :lazy-src="require(`../../assets/Mini-Pictures/${getImage(artPiece.title).split('.')[0]}.jpg`)"
                                        max-height="250"
                                        max-width="310"
                                        height="250"
                                        width="310"
                                        position="center"
                                        :alt="artPiece.title.split('|')[languageId]"
                                    >
                                        <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-progress-circular
                                                    indeterminate
                                                    color="grey lighten-5"
                                                ></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                    <v-card-title class="mt-2" style="padding:0px;">
                                        <v-row no-gutters>
                                            <v-col style="text-align:left;" cols="10">
                                                <h3 style="word-break: break-word; font-size:14px; line-height:20px;" class="poppinsExtraBoldFont">{{artPiece.title.split("|")[languageId]}}</h3>
                                            </v-col>
                                            <v-col style="text-align:right;" cols="2">
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                </v-card>
                            </v-hover>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapState } from "vuex";
    export default {
		name: 'PhotoSelectorModal',
        props: ['dialog', "selectedPhoto"],
        data () {
            return {
            }
        },
        mounted(){
            this.ProcessLastVisited();
        },
        computed:{
            ...mapState("shoppingCart", ["artPieces", "images","size", "printedMedium", "frame", "liner", "artPiecesOk", "propertiesOk", "languageId", "visitedForm"  ]),
            dataReady(){
                return this.artPiecesOk && this.propertiesOk;
            }, 
            availableArtPieces(){
                return this.artPieces.filter(x=>x.price!=null)
            }
        },
        methods: {
            close(){
                this.$emit('closed', false)
            },
			getImage(title){
				var artPiece = this.artPieces.find(x=>x.title.includes(title))
				var images = this.images.find(x=>x.idArtPiece == artPiece.id)
				return images.base64
			},
            select(title){
                this.$emit('imageSelected', title);
                this.close();
            },
            ProcessLastVisited(){
                if(this.visitedForm.size==null) return;
                this.getImage(this.visitedForm.title);
            },
        }
    }
</script>