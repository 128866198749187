<template>
    <v-container fluid style="padding:0px; background-color:#055c67;" :class="displaySizeClass">
        <instructions-step-1></instructions-step-1>
        <v-row no-gutters class="text-center py-4 colorWhite poppinsLightFont" justify="center" v-if="!firstLanding">
            <v-col cols="12" v-if="isSmallScreen!='Mobile'">
                <v-btn @click="resetUploadedImage()" color="primary" class="mr-2">{{takeNewPhoto[languageId]}}</v-btn>
                <v-btn @click="resetBoxDrawing()" color="primary" class="ml-2">{{resetPreviewLabel[languageId]}}</v-btn>
            </v-col>
            <template v-else>
                <v-col cols="12">
                    <v-btn @click="resetUploadedImage()" color="primary" class="mr-2">{{takeNewPhoto[languageId]}}</v-btn>
                </v-col>
                <v-col cols="12" class="mt-2">
                    <v-btn @click="resetBoxDrawing()" color="primary" class="ml-2">{{resetPreviewLabel[languageId]}}</v-btn>
                </v-col>
            </template>
        </v-row>
        <v-row id="picdiv_1" no-gutters style="margin:auto;">
            <v-col cols="12" v-if="imageSRC!=null">
                <rectangleDrawing v-if="imageSRC!=null" :imageWidth="imageWidth" :imageHeight="imageHeight" :imageSRC="imageSRC" :allowDrawing="allowDrawing" :selectedImage="!drawingRect && selectedImage!=null && showImg" @changeSize="changeSize($event)" @position="changeInitialPosition($event)" @recDrawing="recDrawing($event)"/>
                <framedImage v-if="!drawingRect && selectedImage!=null && showImg" :showImg="selectedImage!=null && showImg" :imageSizeX="imageSizeX" :imageSizeY="imageSizeY" :title="selectedImage" :frame="selectedFrame" :liner="selectedLiner" :boxTopX="initialX" :boxTopY="initialY"/>
            </v-col>
        </v-row>
        <v-row no-gutters v-if="image==null">
            <v-col cols="12" class="my-8">
                <v-file-input color="white" :dark="true" style="max-width:250px; margin:auto;" :label="takeFotoLabel.split('|')[languageId]" prepend-icon="mdi-camera" accept="image/png, image/jpeg, image/bmp, image/webp" v-model="image" @change="preview($event)"></v-file-input>
            </v-col>
        </v-row>
        <v-row no-gutters v-else>
            <v-col cols="6" class="my-8"  v-if="isSmallScreen!='Mobile'">
                <div style="display:flex; flex-direction:row; justify-content:end;">
                    <v-btn color="secondary" class="mr-8" @click="photoSelectorDialog = true" :disabled="enableArtPiecesButton">{{galeryLabel.split('|')[languageId]}}</v-btn>
                </div>
            </v-col>
            <v-col cols="12" class="my-2"  v-else>
                <div style="display:flex; flex-direction:row; justify-content:center;">
                    <v-btn color="secondary" @click="photoSelectorDialog = true" :disabled="enableArtPiecesButton">{{galeryLabel.split('|')[languageId]}}</v-btn>
                </div>
            </v-col>
            <v-col cols="6" class="my-8"  v-if="isSmallScreen!='Mobile'">
                <v-btn color="secondary" class="ml-8" @click="photoSettingsDialog = true" :disabled="enableSettingsButton">{{showFotoLabel.split('|')[languageId]}}</v-btn>
            </v-col>
            <v-col cols="12" class="mb-8 mt-2"  v-else>
                <div style="display:flex; flex-direction:row; justify-content:center;">
                    <v-btn color="secondary" @click="photoSettingsDialog = true" :disabled="enableSettingsButton">{{showFotoLabel.split('|')[languageId]}}</v-btn>
                </div>
            </v-col>
            <v-col cols="12" class="mb-8 mt-2">
                <div style="display:flex; flex-direction:row; justify-content:center;">
                    <v-btn color="secondary" @click="addToCart()" class="ml-2" :disabled="!(selectedImage!=null && showImg)">
                        {{addToCartLabel[languageId]}}
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <photo-selector-modal :dialog="photoSelectorDialog" :selectedPhoto="selectedImage" @closed="closePhotoSelectorDialog" @imageSelected="(title)=>imageSelection(title)"></photo-selector-modal>
        <photo-settings-modal v-if="selectedImage && !allowDrawing" :dialog="photoSettingsDialog" :title="selectedImage" @closed="closePhotoSettingsDialog" @photoConfiguration="(configuration)=>photoConfiguration(configuration)"></photo-settings-modal>
    </v-container>
</template>

<script>
import { mapState } from "vuex";
import InstructionsStep1 from './InstructionsStep1.vue'
import RectangleDrawing from './RectangleDrawing.vue'
import FramedImage from './FramedImage.vue'
import PhotoSelectorModal from './PhotoSelectorModal.vue'
import PhotoSettingsModal from './PhotoSettingsModal.vue'

export default {
    name:"Step1",
    components: {
        InstructionsStep1,
        RectangleDrawing,
        FramedImage,
        PhotoSelectorModal,
        PhotoSettingsModal
    },
    props: ['languageId'],
    data:()=>({
        titleLabel:"Previsualizar|Preview",
        stepsLabel:"Paso a paso|Step by step",
        takeFotoLabel:"Tomar foto|Take picture",
        galeryLabel:"Galería|Art-Pieces",
        showFotoLabel:"Mostrar foto|Show photo",
        resetPreviewLabel:["Reiniciar previsualización","Reset preview"],
        takeNewPhoto:["Tomar foto nueva","Take new picture"],
        addToCartLabel:["Volver al producto", "Return tu product"],
        overlay:false,
        windowX:0,
        windowY:0,
        image:null,
        imageSRC:null,
        allowDrawing:true,
        imageWidth:'100%',
        imageHeight:'100%',
        showImg:false,
        drawingRect:true,
        referenceSizeWidth:21.59,
        referenceSizeHeight:27.94,
        pixelSizeX:null,
        pixelSizeY:null,
        initialX:null,
        initialY:null,
        selectedImage:null,
        selectedFrame:null,
        selectedLiner:null,
        frameSizeX:40,
        frameSizeY:60,
        linerSize:0,
        photoSelectorDialog:false,
        photoSettingsDialog:false,
        firstLanding:true,
        enableArtPiecesButton:true,
    }),
    mounted(){
        const picframe = document.querySelector('#picdiv_1');
        const windowX = picframe.getBoundingClientRect().width;
        // const windowX = window.innerWidth;
        const windowY = window.innerHeight;
        this.windowX = windowX;
        this.windowY = windowY;
        this.ProcessLastVisited()
    },
    computed:{
        ...mapState("shoppingCart", [
            "artPieces", "images","size", "printedMedium", "frame", "liner", 
            "artPiecesOk", "propertiesOk", "lastVisited", "visitedForm","baseArtPiecesUrl", 
        ]),
        isSmallScreen(){
            let screenType = "Desktop";
            if(this.displaySizeClass == 'mobile-size')
                screenType = "Mobile";
            if(this.displaySizeClass == 'tablet-size')
                screenType = "Tablet";
            return screenType
        },
        displaySizeClass(){
            let displayClass = "desktop-size";
            let windowWidth = this.widthSize;
            if(windowWidth <= 640)
                displayClass = "mobile-size";
            if(windowWidth >= 1024 && windowWidth <= 1440)
                displayClass = "tablet-size";
            return displayClass
        },
        widthSize(){
            let windowWidth = window.innerWidth;
            if(windowWidth < 320)
                windowWidth = 320
            if(windowWidth > 640 && windowWidth < 1024)
                windowWidth = 640
            if(windowWidth > 1440 && windowWidth < 1920)
                windowWidth = 1440
            if(windowWidth > 1920)
                windowWidth = 1920
            return windowWidth
        },
        conversionFactor(){
            let referenceSizeX = this.pixelSizeX>this.pixelSizeY?this.referenceSizeHeight:this.referenceSizeWidth;
            let factorX = this.pixelSizeX/referenceSizeX;
            let referenceSizeY = this.pixelSizeX>this.pixelSizeY?this.referenceSizeWidth:this.referenceSizeHeight;
            let factorY = this.pixelSizeY/referenceSizeY;
            return (factorX+factorY)/2;
        },
        imageSizeX(){
            if(this.referenceSizeWidth<=0) return null;
            return this.conversionFactor*(this.frameSizeX + this.linerSize);
        },
        imageSizeY(){
            if(this.referenceSizeHeight<=0) return null;
            return this.conversionFactor*(this.frameSizeY + this.linerSize);
        },
        appBar(){
            if(this.isSmallScreen != "Mobile")
                return 75;
            return 56
        },
        enableSettingsButton(){
            return this.allowDrawing || (!this.selectedImage && this.enableArtPiecesButton);
        },
    },
    methods:{
        ProcessLastVisited(){
            // if(!this.lastVisited) return;
            // let selectedArtPiece = this.artPieces.find(x=>x.id == this.lastVisited);
            // if(selectedArtPiece?.title != this.visitedForm.title) return;
            if(this.visitedForm.title=='') return;
            this.imageSelection(this.visitedForm.title);
            if(this.visitedForm.size==null) return;
            this.photoConfiguration(this.visitedForm)
        },
        NextStep(step){
            this.$emit('NextStep', step)
        },
        readURL(file){
            if(file == null) return null;
            return new Promise((res, rej) => {
                const reader = new FileReader();
                reader.onload = e => res(e.target.result);
                reader.onerror = e => rej(e);
                reader.readAsDataURL(file);
            });
        },
        async createImage(url){
            let image = new Image();
            image.src = url;
            return image;
        },
        async preview(file){
            this.firstLanding=false;
            const url = await this.readURL(file);
            if(url == null) return;
            const image = await this.createImage(url);
            setTimeout(() => {
                const XY = this.calcImageSizes(image.width,image.height)
                this.imageSRC = url;
                this.imageWidth = XY[0];
                this.imageHeight = XY[1];
                this.overlay = true;
            }, 30);
        },
        calcImageSizes(width, height){
            let XY = [null,null];
            const windowY = this.windowY - this.appBar;
            if(width>=height){ //landscape
                if(width > this.windowX){
                    let percentage = 0;
                    percentage = width / this.windowX;
                    XY[0] = this.windowX
                    XY[1] = (height / percentage);
                    if(XY[1] > windowY){
                        let percentage2 = 0;
                        percentage2 = XY[1] / windowY;
                        XY[1] = windowY
                        XY[0] = XY[0] / percentage2;
                    }
                }
                else{
                    XY = [width,height - this.appBar]
                }
            }
            else{ //portrait
                if(height > windowY){
                    let percentage = 0;
                    percentage = height / windowY;
                    XY[1] = windowY
                    XY[0] = width / percentage;
                    if(XY[0] > this.windowX){
                        let percentage2 = 0;
                        percentage2 = XY[0] / this.windowX;
                        XY[0] = this.windowX
                        XY[1] = (XY[1] / percentage2);
                    }
                }
                else{
                    XY = [width,height - this.appBar]
                }
            }
            return XY
        },
        changeSize(value){
            this.allowDrawing = false;
            this.enableArtPiecesButton = false;
            this.pixelSizeX = value[0];
            this.pixelSizeY = value[1];
        },
        recDrawing(value){
            this.drawingRect = value;
        },
        changeInitialPosition(value){
            this.initialX = value[0];
            this.initialY = value[1];
        },
        closePhotoSelectorDialog(value){
            this.photoSelectorDialog = value;
        },
        closePhotoSettingsDialog(value){
            this.photoSettingsDialog = value;
        },
        resetBoxDrawing(){
            this.allowDrawing = true;
            this.enableArtPiecesButton = true;
            this.resetImageDrawing();
        },
        imageSelection(title){
            this.resetImageDrawing();
            this.selectedImage = title;
        },
        resetImageDrawing(){
            this.showImg=false;
            this.drawingRect=true;
            this.selectedFrame = null;
            this.selectedLiner = null;
            this.frameSizeX = 0;
            this.frameSizeY = 0;
            this.linerSize = 0;
        },
        photoConfiguration(configuration){
            let idFrame = configuration.frame
            let idLiner = configuration.liner
            let idSize = configuration.size
            this.selectedFrame = idFrame;
            this.selectedLiner = idLiner;
            let liner = this.liner.find(x=>x.id == idLiner)
            this.linerSize = liner.size*2;
            let size = this.size.find(x=>x.id == idSize);
            let width = size?.width;
            let height = size?.height;
            let frameSize = idFrame!=1?3:0;
            this.frameSizeX = width+frameSize
            this.frameSizeY = height+frameSize
            this.showImg=true;
            this.drawingRect = false;
        },
        resetUploadedImage(){
            this.image = null;
            this.imageSRC = null;
            this.resetBoxDrawing();
        },
        addToCart(){
            let theme = ["Todo","All"]
            let languageId = this.languageId;
            this.$router.push(`/${this.baseArtPiecesUrl[languageId]}/${theme[languageId]}/${this.selectedImage.split("|")[languageId]}`)
        },
    }
}
</script>