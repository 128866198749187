var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.title)?_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":this.$vuetify.breakpoint.name!='xs'?600:310},on:{"click:outside":function($event){return _vm.close()}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-center colorYellow"},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row","width":"100%","justify-content":"center"}},[_c('div',{staticStyle:{"width":"20%"}},[_c('hr',{staticClass:"mt-4",staticStyle:{"color":"#D79A2B"}})]),_c('div',[_c('h5',{staticClass:"poppinsRegularFont mx-6",staticStyle:{"text-transform":"uppercase","font-size":"22px"}},[_vm._v(_vm._s(["Configuración Foto","Photo Settings"][_vm.languageId]))])]),_c('div',{staticStyle:{"width":"20%"}},[_c('hr',{staticClass:"mt-4",staticStyle:{"color":"#D79A2B"}})]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-row',{attrs:{"no-gutters":"","align-content":"center"}},[_c('v-col',{staticClass:"pr-4",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({staticClass:"poppinsLightFont",staticStyle:{"max-width":"230px","margin":"auto"},attrs:{"items":_vm.artPieceSizes(_vm.display.sizes),"item-text":"name","item-value":"id","error-messages":_vm.sizeError?_vm.sizeErrorMessage[_vm.languageId]:[],"color":"#848484","item-color":"#848484"},on:{"click":function($event){_vm.sizeError=false},"change":function (idSize) { return _vm.setVisitedForm({with:idSize,property:'size'}); }},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"colorYellow poppinsRegularFont uppercase",staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(_vm.sizeLabel[_vm.languageId])+" "),_c('v-icon',{attrs:{"small":"","color":"colorDarkGrey"}},[_vm._v("mdi-information-outline")])],1)]},proxy:true}],null,true),model:{value:(_vm.form.size),callback:function ($$v) {_vm.$set(_vm.form, "size", $$v)},expression:"form.size"}},'v-select',attrs,false),on))]}}],null,false,2619868802)},[_c('span',[_vm._v(_vm._s(_vm.sizeHint[_vm.languageId]))])])],1),_c('v-col',{staticClass:"pr-4",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({staticClass:"poppinsLightFont",staticStyle:{"max-width":"230px","margin":"auto"},attrs:{"items":_vm.artPiecesFrames(_vm.display.frames),"item-text":"name","item-value":"id","error-messages":_vm.frameError?_vm.frameErrorMessage[_vm.languageId]:[],"color":"#848484","item-color":"#848484"},on:{"click":function($event){_vm.frameError=false},"change":function (idFrame) { return _vm.setVisitedForm({with:idFrame,property:'frame'}); }},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"colorYellow poppinsRegularFont uppercase",staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(_vm.frameLabel[_vm.languageId])+" "),_c('v-icon',{attrs:{"small":"","color":"colorDarkGrey"}},[_vm._v("mdi-information-outline")])],1)]},proxy:true}],null,true),model:{value:(_vm.form.frame),callback:function ($$v) {_vm.$set(_vm.form, "frame", $$v)},expression:"form.frame"}},'v-select',attrs,false),on))]}}],null,false,189414193)},[_c('span',[_vm._v(_vm._s(_vm.frameHint[_vm.languageId]))])])],1),_c('v-col',{staticClass:"pr-4",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({staticClass:"poppinsLightFont",staticStyle:{"max-width":"230px","margin":"auto"},attrs:{"items":_vm.artPiecesLiners(_vm.display.liners),"item-text":"name","item-value":"id","error-messages":_vm.linerError?_vm.linerErrorMessage[_vm.languageId]:[],"color":"#848484","item-color":"#848484"},on:{"click":function($event){_vm.linerError=false},"change":function (idLiner) { return _vm.setVisitedForm({with:idLiner,property:'liner'}); }},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"colorYellow poppinsRegularFont uppercase",staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(_vm.linerLabel[_vm.languageId])+" "),_c('v-icon',{attrs:{"small":"","color":"colorDarkGrey"}},[_vm._v("mdi-information-outline")])],1)]},proxy:true}],null,true),model:{value:(_vm.form.liner),callback:function ($$v) {_vm.$set(_vm.form, "liner", $$v)},expression:"form.liner"}},'v-select',attrs,false),on))]}}],null,false,2430532593)},[_c('span',[_vm._v(_vm._s(_vm.linerHint[_vm.languageId]))])])],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.acceptConfiguration()}}},[_vm._v(" "+_vm._s(["Aceptar", "Accept"][_vm.languageId])+" ")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }