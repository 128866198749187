<template>
    <v-row no-gutters class="text-center py-4 colorWhite poppinsLightFont" justify="center">
        <v-col cols="12">
            <h1 class="lusciousLifestylesScriptFont mb-8" style="font-size:90px;">{{titleLabel.split("|")[languageId]}}</h1>
        </v-col>
        <v-col cols="12" class="mb-4" v-if="isSmallScreen!='Mobile'">
            <div class="mx-8 px-8 py-2" style="text-align:justify; border: 1px solid #D79A2B;">
                <h2 class="px-2 text-yellow poppinsSemiboldFont" style="margin-top:-20px; background-color:#055c67; width:max-content; text-transform: uppercase; font-size:16px;">{{stepsLabel.split("|")[languageId]}}</h2>
                <div class="px-4 my-2" v-if="languageId == 0" style="display:flex; flex-direction:column;">
                    <div style="display:flex; flex-direction:row;">
                        <span class="mr-4">1. </span>
                        <p>
                            Toma una foto del espacio donde pegaste la hoja presionando el boton <span class="text-yellow poppinsSemiboldFont">"{{takeFotoLabel.split("|")[languageId]}}"</span> abajo.<!-- <span class="text-yellow poppinsSemiboldFont">Recuerda tomar la foto de forma vertical</span>. --> 
                            <br/>Puedes tomar una nueva foto del espacio utilizando el botón <span class="text-yellow poppinsSemiboldFont">"{{takeNewPhoto[languageId]}}"</span>
                        </p>
                    </div>
                    <div style="display:flex; flex-direction:row;">
                        <span class="mr-4">2. </span>
                        <p>Aparecerá la foto que tomaste y deberás con tu mouse <span class="text-yellow poppinsSemiboldFont">seleccionar el espacio de la foto donde se encuentra la hoja que pegaste</span>. Aparecerá un rectángulo amarillo mostrando tu selección.
                            <br/>Puedes repetir la selección en caso de no lograrlo bien al primer intento utilizando el botón <span class="text-yellow poppinsSemiboldFont">"Reiniciar previsualización"</span>.
                        </p>
                    </div>
                    <div style="display:flex; flex-direction:row;">
                        <span class="mr-4">3. </span>
                        <p>Con el botón <span class="text-yellow poppinsSemiboldFont">"Galería"</span> selecciona la foto que quieres ver en tu espacio.</p>
                    </div>
                    <div style="display:flex; flex-direction:row;">
                        <span class="mr-4">4. </span>
                        <p>A continuación presiona el botón <span class="text-yellow poppinsSemiboldFont">"Mostrar foto"</span> donde puedes configurar las opciones que deseas para esta, por ejemplo: tamaño, marco, paspartú.
                            <br/>Presiona el botón <span class="text-yellow poppinsSemiboldFont">"Aceptar"</span> para terminar tu seleccion y ver tu futura foto en tu espacio.
                        </p>
                    </div>
                    <div style="display:flex; flex-direction:row;">
                        <span class="mr-4">5. </span>
                        <p>Aparecerá la foto con las opciones seleccionadas en tu espacio y podrás <span class="text-yellow poppinsSemiboldFont">moverla con click sostenido del mouse</span> para encontrar la ubicación perfecta.</p>
                    </div>
                    <div style="display:flex; flex-direction:row;">
                        <span class="mr-4">6. </span>
                        <p>
                            Si después quieres <span class="text-yellow poppinsSemiboldFont">probar con otra foto</span> puedes hacerlo, repitiendo el proceso desde el paso 3.
                            <br/>Pero si solo deseas <span class="text-yellow poppinsSemiboldFont">cambiar las opciones de la foto que estas viendo</span>, puedes hacerlo repitiendo el proceso desde el paso 4.
                        </p>
                    </div>
                </div>
                <div class="px-4 my-2" v-if="languageId == 1" style="display:flex; flex-direction:column;">
                    <div style="display:flex; flex-direction:row;">
                        <span class="mr-4">1. </span>
                        <p>
                            Take a picture of the space where you put the sheet of paper by pressing the <span class="text-yellow poppinsSemiboldFont">"{{takeFotoLabel.split("|")[languageId]}}"</span> button below.<!-- <span class="text-yellow poppinsSemiboldFont">Remember to take the photo vertically</span>. -->
                            <br/>You can take a new picture of the space by clicking on the <span class="text-yellow poppinsSemiboldFont">"{{takeNewPhoto[languageId]}}"</span> button.
                        </p>
                    </div>
                    <div style="display:flex; flex-direction:row;">
                        <span class="mr-4">2. </span>
                        <p>
                            The picture you took will appear and you can use your mouse to <span class="text-yellow poppinsSemiboldFont">select the place where the sheet of paper is located</span>. A yellow rectangle will appear showing your selection.
                            <br/>You can repeat the selection process if you don't get it right on the first try by using the <span class="text-yellow poppinsSemiboldFont">"{{resetPreviewLabel[languageId]}}"</span> button.
                        </p>
                    </div>
                    <div style="display:flex; flex-direction:row;">
                        <span class="mr-4">3. </span>
                        <p>
                            With the <span class="text-yellow poppinsSemiboldFont">"{{galeryLabel.split('|')[languageId]}}"</span> button select the photo you want to see in your space.
                        </p>
                    </div>
                    <div style="display:flex; flex-direction:row;">
                        <span class="mr-4">4. </span>
                        <p>
                            Then press the <span class="text-yellow poppinsSemiboldFont">"{{showFotoLabel.split('|')[languageId]}}"</span> button, where you can set the options you want for the photo, for example: size, frame, liner.
                            <br/>Press the <span class="text-yellow poppinsSemiboldFont">"Accept"</span> button to finish your selection and see your future photo in your space.
                        </p>
                    </div>
                    <div style="display:flex; flex-direction:row;">
                        <span class="mr-4">5. </span>
                        <p>
                            The photo will appear with the selected options in your space and you can <span class="text-yellow poppinsSemiboldFont">click and drag to move it</span> to find the perfect location.
                        </p>
                    </div>
                    <div style="display:flex; flex-direction:row;">
                        <span class="mr-4">6. </span>
                        <p>
                            If you later want to <span class="text-yellow poppinsSemiboldFont">try another photo</span>, you can do so by repeating the process from step number 3.
                            <br/>But if you only want to <span class="text-yellow poppinsSemiboldFont">change the options of the photo you are viewing</span>, you can do it by repeating the process from step number 4.
                        </p>
                    </div>
                </div>
            </div>
        </v-col>
        <v-col cols="12" class="mb-4" v-if="isSmallScreen=='Mobile'">
            <div class="mx-4 px-4 py-2" style="text-align:justify; border: 1px solid #D79A2B;">
                <h2 class="px-2 text-yellow poppinsSemiboldFont" style="margin-top:-20px; background-color:#055c67; width:max-content; text-transform: uppercase; font-size:16px;">{{stepsLabel.split("|")[languageId]}}</h2>
                <div class="px-2 my-2" v-if="languageId == 0" style="display:flex; flex-direction:column;">
                    <span class="mr-4">1. </span>
                    <p>
                        Toma una foto del espacio donde pegaste la hoja presionando el boton <span class="text-yellow poppinsSemiboldFont">"{{takeFotoLabel.split("|")[languageId]}}"</span> abajo.<!-- <span class="text-yellow poppinsSemiboldFont">Recuerda tomar la foto de forma vertical</span>. --> 
                        <br/>Puedes tomar una nueva foto del espacio utilizando el botón <span class="text-yellow poppinsSemiboldFont">"{{takeNewPhoto[languageId]}}"</span>
                    </p>
                    <span class="mr-4">2. </span>
                    <p>Aparecerá la foto que tomaste y deberás con tu mouse <span class="text-yellow poppinsSemiboldFont">seleccionar el espacio de la foto donde se encuentra la hoja que pegaste</span>. Aparecerá un rectángulo amarillo mostrando tu selección.
                        <br/>Puedes repetir la selección en caso de no lograrlo bien al primer intento utilizando el botón <span class="text-yellow poppinsSemiboldFont">"Reiniciar previsualización"</span>.
                    </p>
                    <span class="mr-4">3. </span>
                    <p>Con el botón <span class="text-yellow poppinsSemiboldFont">"Galería"</span> selecciona la foto que quieres ver en tu espacio.</p>
                    <span class="mr-4">4. </span>
                    <p>A continuación presiona el botón <span class="text-yellow poppinsSemiboldFont">"Mostrar foto"</span> donde puedes configurar las opciones que deseas para esta, por ejemplo: tamaño, marco, paspartú.
                        <br/>Presiona el botón <span class="text-yellow poppinsSemiboldFont">"Aceptar"</span> para terminar tu seleccion y ver tu futura foto en tu espacio.
                    </p>
                    <span class="mr-4">5. </span>
                    <p>Aparecerá la foto con las opciones seleccionadas en tu espacio y podrás <span class="text-yellow poppinsSemiboldFont">moverla con click sostenido del mouse</span> para encontrar la ubicación perfecta.</p>
                    <span class="mr-4">6. </span>
                    <p>
                        Si después quieres <span class="text-yellow poppinsSemiboldFont">probar con otra foto</span> puedes hacerlo, repitiendo el proceso desde el paso 3.
                        <br/>Pero si solo deseas <span class="text-yellow poppinsSemiboldFont">cambiar las opciones de la foto que estas viendo</span>, puedes hacerlo repitiendo el proceso desde el paso 4.
                    </p>
                </div>
                <div class="px-2 my-2" v-if="languageId == 1" style="display:flex; flex-direction:column;">
                    <span class="mr-4">1. </span>
                    <p>
                        Take a picture of the space where you put the sheet of paper by pressing the <span class="text-yellow poppinsSemiboldFont">"{{takeFotoLabel.split("|")[languageId]}}"</span> button below.<!-- <span class="text-yellow poppinsSemiboldFont">Remember to take the photo vertically</span>. -->
                        <br/>You can take a new picture of the space by clicking on the <span class="text-yellow poppinsSemiboldFont">"{{takeNewPhoto[languageId]}}"</span> button.
                    </p>
                    <span class="mr-4">2. </span>
                    <p>
                        The picture you took will appear and you can use your mouse to <span class="text-yellow poppinsSemiboldFont">select the place where the sheet of paper is located</span>. A yellow rectangle will appear showing your selection.
                        <br/>You can repeat the selection process if you don't get it right on the first try by using the <span class="text-yellow poppinsSemiboldFont">"{{resetPreviewLabel[languageId]}}"</span> button.
                    </p>
                    <span class="mr-4">3. </span>
                    <p>
                        With the <span class="text-yellow poppinsSemiboldFont">"{{galeryLabel.split('|')[languageId]}}"</span> button select the photo you want to see in your space.
                    </p>
                    <span class="mr-4">4. </span>
                    <p>
                        Then press the <span class="text-yellow poppinsSemiboldFont">"{{showFotoLabel.split('|')[languageId]}}"</span> button, where you can set the options you want for the photo, for example: size, frame, liner.
                        <br/>Press the <span class="text-yellow poppinsSemiboldFont">"Accept"</span> button to finish your selection and see your future photo in your space.
                    </p>
                    <span class="mr-4">5. </span>
                    <p>
                        The photo will appear with the selected options in your space and you can <span class="text-yellow poppinsSemiboldFont">click and drag to move it</span> to find the perfect location.
                    </p>
                    <span class="mr-4">6. </span>
                    <p>
                        If you later want to <span class="text-yellow poppinsSemiboldFont">try another photo</span>, you can do so by repeating the process from step number 3.
                        <br/>But if you only want to <span class="text-yellow poppinsSemiboldFont">change the options of the photo you are viewing</span>, you can do it by repeating the process from step number 4.
                    </p>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
    name:"InstructionsStep1",
    components: {
    },
    props: [],
    data:()=>({
        titleLabel:"Previsualizar|Preview",
        stepsLabel:"Paso a paso|Step by step",
        takeFotoLabel:"Tomar foto|Take picture",
        galeryLabel:"Galería|Art-Pieces",
        showFotoLabel:"Mostrar foto|Show photo",
        resetPreviewLabel:["Reiniciar previsualización","Reset preview"],
        takeNewPhoto:["Tomar foto nueva","Take new picture"],
    }),
    mounted(){
    },
    computed:{
        ...mapState("shoppingCart", ["artPieces", "images","size", "printedMedium", "frame", "liner", "artPiecesOk", "propertiesOk", "languageId" ]),
        isSmallScreen(){
            let screenType = "Desktop";
            if(this.displaySizeClass == 'mobile-size')
                screenType = "Mobile";
            if(this.displaySizeClass == 'tablet-size')
                screenType = "Tablet";
            return screenType
        },
        displaySizeClass(){
            let displayClass = "desktop-size";
            let windowWidth = this.widthSize;
            if(windowWidth <= 640)
                displayClass = "mobile-size";
            if(windowWidth >= 1024 && windowWidth <= 1440)
                displayClass = "tablet-size";
            return displayClass
        },
        widthSize(){
            let windowWidth = window.innerWidth;
            if(windowWidth < 320)
                windowWidth = 320
            if(windowWidth > 640 && windowWidth < 1024)
                windowWidth = 640
            if(windowWidth > 1440 && windowWidth < 1920)
                windowWidth = 1440
            if(windowWidth > 1920)
                windowWidth = 1920
            return windowWidth
        },
    },
    methods:{
    }
}
</script>