<template>
    <div
        id="picmodel_1"
        ondragstart="return false;"
        style="position:absolute;" 
        :style="`top:${positionImagenX}px;left:${positionImagenY}px;width:${imageSizeX}px;height:${imageSizeY}px;cursor:${isMoving?'grabbing':'move'};${!isMoving?'touch-action:none;':''}`"
        @mousedown="mouseDown($event)"
        @mousemove="mouseMove($event)"
        @mouseup="mouseUp($event)"
        @touchstart="touchStart($event)"
        @touchmove="touchMove($event)"
        @touchend="touchEnd($event)"
    >
        <img 
            v-if="showImg" 
            :src="require(`../../assets/${getImage(title)}`)"
            :lazy-src="require(`../../assets/Mini-Pictures/${getMiniImage(title).split('.')[0]}.jpg`)"
            class="noSelect"
            :style="`width:${imageSizeX}px;height:${imageSizeY}px;`"

        />
    </div>

</template>

<script>
import { mapState } from "vuex";
    export default {
        name: 'FramedImage',
        props:[
            'showImg',
            'imageSizeX',
            'imageSizeY',
            'title',
            'frame',
            'liner',
            'boxTopX',
            'boxTopY'
        ],
        data: () => ({
            isMoving:false,
            positionImagenX:350,
            positionImagenY:450,
            mountedX:null,
            mountedY:null,
            startPos:[],
            newPos:[],
            startDrag:false,
            startTDrag:false,
        }),
        computed:{
            ...mapState("shoppingCart", ["artPieces", "images"]),
            appBar(){
                if(this.isSmallScreen != "Mobile")
                    return 128;
                return 56;
            },
            isSmallScreen(){
                var screenType = "Desktop";
                if(this.displaySizeClass == 'mobile-size')
                    screenType = "Mobile";
                if(this.displaySizeClass == 'tablet-size')
                    screenType = "Tablet";
                return screenType
            },
            displaySizeClass(){
                var displayClass = "desktop-size";
                var windowWidth = this.widthSize;
                if(windowWidth <= 640)
                    displayClass = "mobile-size";
                if(windowWidth >= 1024 && windowWidth <= 1440)
                    displayClass = "tablet-size";
                return displayClass
            },
            widthSize(){
                var windowWidth = window.innerWidth;
                if(windowWidth < 320)
                    windowWidth = 320
                if(windowWidth > 640 && windowWidth < 1024)
                    windowWidth = 640
                if(windowWidth > 1440 && windowWidth < 1920)
                    windowWidth = 1440
                if(windowWidth > 1920)
                    windowWidth = 1920
                return windowWidth
            },
        },
        methods:{
			getImage(title){
				var artPiece = this.artPieces.find(x=>x.title.includes(title))
				var images = this.images.find(x=>x.idArtPiece == artPiece.id && x.idFrame == this.frame && x.idLiner == this.liner && x.idImageType == 4)
				return images.base64
			},
            getMiniImage(title){
				var artPiece = this.artPieces.find(x=>x.title.includes(title))
				var images = this.images.find(x=>x.idArtPiece == artPiece.id && x.idImageType == 4)
				return images.base64
            },
            mouseDown(event){
                if(!this.startDrag){
                    const picframe = document.querySelector('#picdiv_1');
                    const bclient = picframe.getBoundingClientRect();
                    this.startPos = [event.clientX  - event.layerX, event.pageY - this.appBar - event.offsetY, event.clientX, event.clientY];
                    this.positionImagenX = this.startPos[1];
                    this.positionImagenY = this.startPos[0]  - bclient.x;
                    this.isMoving = true;
                    this.startDrag = true;
                }
            },
            touchStart(event){
                if(!this.startTDrag){
                    let touches = event.touches;
                    if(touches.length != 1) return null;
                    let touch = touches[0];
                    this.startPos = [touch.pageX , touch.pageY, this.positionImagenY, this.positionImagenX]
                    this.positionImagenX = this.startPos[3];
                    this.positionImagenY = this.startPos[2];
                    this.isMoving = true;
                    this.startTDrag = true;
                }
            },
            mouseUp(){
                this.startDrag = false;
                this.isMoving = false;
            },
            touchEnd(){
                this.startTDrag = false;
                this.isMoving = false;
            },
            mouseMove(event){
                if(this.startDrag && event.type == "mousemove"){
                    const picframe = document.querySelector('#picdiv_1');
                    const bclient = picframe.getBoundingClientRect();
                    this.newPos[1] = this.startPos[3] - event.clientY;
                    this.newPos[0] = this.startPos[2] - event.clientX + 2 + bclient.x;
                    this.positionImagenX = this.startPos[1] - this.newPos[1];
                    this.positionImagenY = this.startPos[0] - this.newPos[0];
                    this.isMoving = true;
                }
            },
            touchMove(event){
                if (this.startTDrag && event.type == "touchmove") {
                    let touches = event.touches;
                    if(touches.length != 1) return null;
                    let touch = touches[0];
                    this.newPos[1] = this.startPos[1] - touch.pageY;
                    this.newPos[0] = this.startPos[0] - touch.pageX;
                    this.positionImagenX = this.startPos[3] - this.newPos[1];
                    this.positionImagenY = this.startPos[2] - this.newPos[0];
                }
            },
        },
        mounted(){
            this.mountedX = this.boxTopX;
            this.mountedY = this.boxTopY;
            this.positionImagenX = this.boxTopX;
            this.positionImagenY = this.boxTopY;
        },
    }
</script>
