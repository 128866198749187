<template>
    <div class="text-center" v-if="title">
        <v-dialog
            v-model="dialog"
            :width="this.$vuetify.breakpoint.name!='xs'?600:310"
            @click:outside="close()"
        >

            <v-card>
                <v-card-title class="text-center colorYellow">
                    <div style="display:flex; flex-direction:row; width:100%; justify-content:center;">
                        <div style="width:20%;">
                            <hr class="mt-4" style="color:#D79A2B;"/>
                        </div>
                        <div>
                            <h5 class="poppinsRegularFont mx-6" style="text-transform:uppercase; font-size:22px;">{{["Configuración Foto","Photo Settings"][languageId]}}</h5>
                        </div>
                        <div style="width:20%;">
                            <hr class="mt-4" style="color:#D79A2B;"/>
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="error"
                            @click="close()"
                            icon
                        >
                            <v-icon >mdi-close</v-icon>
                        </v-btn>
                    </div>
                </v-card-title>

                <v-card-text>
                    <v-form ref="form" >
                        <v-row no-gutters align-content="center">
                            <v-col cols="12" class="pr-4" >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-select 
                                            v-bind="attrs"
                                            v-on="on"
                                            v-model="form.size"
                                            :items="artPieceSizes(display.sizes)"
                                            item-text="name"
                                            item-value="id"
                                            :error-messages="sizeError?sizeErrorMessage[languageId]:[]"
                                            @click="sizeError=false"
                                            style="max-width:230px; margin:auto;"
                                            color="#848484"
                                            item-color="#848484"
                                            class="poppinsLightFont"
                                            @change="idSize => setVisitedForm({with:idSize,property:'size'})"
                                        >
                                            <template v-slot:label>
                                                <p class="colorYellow poppinsRegularFont uppercase" style="font-size:20px;">{{sizeLabel[languageId]}} <v-icon small color="colorDarkGrey">mdi-information-outline</v-icon></p>
                                            </template>
                                        </v-select>
                                    </template>
                                    <span>{{sizeHint[languageId]}}</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="12" class="pr-4" >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-select
                                            v-bind="attrs"
                                            v-on="on"
                                            v-model="form.frame"
                                            :items="artPiecesFrames(display.frames)"
                                            item-text="name"
                                            item-value="id"
                                            :error-messages="frameError?frameErrorMessage[languageId]:[]"
                                            @click="frameError=false"
                                            style="max-width:230px; margin:auto;"
                                            color="#848484"
                                            item-color="#848484"
                                            class="poppinsLightFont"
                                            @change="idFrame => setVisitedForm({with:idFrame,property:'frame'})"
                                        >
                                            <template v-slot:label>
                                                <p class="colorYellow poppinsRegularFont uppercase" style="font-size:20px;">{{frameLabel[languageId]}} <v-icon small color="colorDarkGrey">mdi-information-outline</v-icon></p>
                                            </template>
                                        </v-select>
                                    </template>
                                    <span>{{frameHint[languageId]}}</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="12" class="pr-4">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-select
                                            v-bind="attrs"
                                            v-on="on"
                                            v-model="form.liner"
                                            :items="artPiecesLiners(display.liners)"
                                            item-text="name"
                                            item-value="id"
                                            :error-messages="linerError?linerErrorMessage[languageId]:[]"
                                            @click="linerError=false"
                                            style="max-width:230px; margin:auto;"
                                            color="#848484"
                                            item-color="#848484"
                                            class="poppinsLightFont"
                                            @change="idLiner => setVisitedForm({with:idLiner,property:'liner'})"
                                        >
                                            <template v-slot:label>
                                                <p class="colorYellow poppinsRegularFont uppercase" style="font-size:20px;">{{linerLabel[languageId]}} <v-icon small color="colorDarkGrey">mdi-information-outline</v-icon></p>
                                            </template>
                                        </v-select>
                                    </template>
                                    <span>{{linerHint[languageId]}}</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="acceptConfiguration()" >
                            {{["Aceptar", "Accept"][languageId]}}
                        </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
    export default {
		name: 'PhotoSettingsModal',
        props: ['dialog', 'title',],
        data () {
            return {
                form:{
                    title:"",
                    size:null,
                    printedMedium:null,
                    frame:null,
                    liner:null,
                },
                display:undefined,
                sizeError:false,
                printedMediumError:false,
                frameError:false,
                linerError:false,
            }
        },
        computed:{
            ...mapState("shoppingCart", 
                [ 
                    "artPieces", "languageId", "metricSystemId",  "soldUnits", "amountLabel", "comingSoonLabel", "newLabel",
                    "size", "printedMedium", "frame", "liner", "shoppingCart", "moreDetailsLabel", "addToCartLabel", 
                    "priceLabel","sizeLabel","printedMediumLabel","frameLabel","linerLabel","inStockLabel","outOfStockLabel","sizeErrorMessage","printedMediumErrorMessage","frameErrorMessage","linerErrorMessage","sizeHint","printedMediumHint","frameHint","linerHint","addToCartLabel",
                    "visitedForm"
                ]),
        },
        async mounted(){
            this.initial();
        },
        updated(){
            if(this.display.title != this.title){
                this.initial();
            }
        },
        methods: {
            ...mapActions("shoppingCart", ["setVisitedForm" ]),
            initial(){
                var display = this.artPieces.find(x=>x.title.includes(this.title))
                if(display != undefined){
                    this.display = display;
                    this.form = {
                        title:display.title,
                    }
                    this.ProcessLastVisited();
                }
                else{
                    this.close();
                }
            },
            acceptConfiguration(){
                this.$emit('photoConfiguration', this.form)
                this.close();
            },
            close(){
                this.$emit('closed', false)
            },
            getSizeIndex(){
                var sizes = this.artPieceSizes(this.display.sizes)
                return sizes.indexOf(sizes.find(x=>x.id == this.form.size))
            },
            artPieceSizes(sizes){
                return this.size.filter(x=>sizes.split("|").includes(x.id.toString())).map(x=>{return {id:x.id,name:x.name.split("|")[this.metricSystemId]}})
            },
            artPiecesPrintedMediums(printedMediums){
                return this.printedMedium.filter(x=>printedMediums.split("|").includes(x.id.toString())).map(x=>{return {id:x.id,name:x.name.split("|")[this.languageId]}})
            },
            artPiecesFrames(frames){
                return this.frame.filter(x=>frames.split("|").includes(x.id.toString())).map(x=>{return {id:x.id,name:x.name.split("|")[this.languageId]}})
            },
            artPiecesLiners(liners){
                return this.liner.filter(x=>liners.split("|").includes(x.id.toString())).map(x=>{return {id:x.id,name:x.name.split("|")[this.languageId]}})
            },
            ProcessLastVisited(){
                if(this.visitedForm.size==null) return;
                this.form = this.visitedForm;
            },
        }
    }
</script>